import React from 'react';

function Kbit({ kbitData }) {
    const getClassForChange = (change) => {
        if (change < 0) {
            return "negative-change"; // Class for negative numbers
        } else if (change > 0) {
            return "positive-change"; // Class for positive numbers
        } else {
            return "";
        }
    };

    const formatCurrency = (value) => {
        return `$${value.toFixed(0).replace(/\d(?=(\d{3})+$)/g, '$&,')}`;
    };

    return (
        <div>
            <table>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Daily PNL Estimate ($)</th>
                        <th>Daily PNL Estimate (%)</th>
                        <th>Daily PNL (Annualised)</th>
                        <th>MTD PNL Estimate ($)</th>
                        <th>MTD PNL Estimate (%)</th>
                    </tr>
                </thead>
                <tbody>
                    {kbitData.map((item, index) => (
                        <tr key={index}>
                            <td>{item["date"]}</td>
                            <td>{formatCurrency(item["dailyPnlEstimate ($)"])}</td>
                            <td className={getClassForChange(item["dailyPnlEstimate (%)"])}>
                                {(item["dailyPnlEstimate (%)"] * 100).toFixed(2)}%
                            </td>
                            <td>{(item["dailyPnl (annualised)"] * 100).toFixed(2)}%</td>
                            <td>{formatCurrency(item["mtdPnlEstimate ($)"])}</td>
                            <td className={getClassForChange(item["mtdPnlEstimate (%)"])}>
                                {(item["mtdPnlEstimate (%)"] * 100).toFixed(2)}%
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default Kbit;
