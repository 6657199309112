import React from 'react';

function Wincent({ wincentData, displayCount, setDisplayCount }) {
    const getClassForChange = (change) => {
        if (change < 0) {
            return "negative-change"; // Class for negative numbers
        } else if (change > 0) {
            return "positive-change"; // Class for positive numbers
        } else {
            return "";
        }
    };

    return (
        <div>
            <table>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Total NAV (US$ Millions)</th>
                        <th>Total Net Debt (US$ Millions)</th>
                        <th>% Change (NAV)</th>
                        <th>% Change Net Debt</th>
                        <th>Change in NAV</th>
                        <th>Change in Debt</th>
                    </tr>
                </thead>
                <tbody>
                    {wincentData.slice(0, displayCount).map((item, index) => (
                        <tr key={index}>
                            <td>{item.date}</td>
                            <td>{item['totalNavUs$ (millions)']}</td>
                            <td>{item['totalNetDebtUs$ (millions)']}</td>
                            <td className={getClassForChange(item['%Change (nav)'])}>
                                {(item['%Change (nav)'] * 100).toFixed(2)}%
                            </td>
                            <td className={getClassForChange(item['%ChangeNetDebt'])}>
                                {(item['%ChangeNetDebt'] * 100).toFixed(2)}%
                            </td>
                            <td className={getClassForChange(item['changeInNav'])}>
                                {item['changeInNav'].toFixed(2)}
                            </td>
                            <td className={getClassForChange(item['changeInDebt'])}>
                                {item['changeInDebt'].toFixed(2)}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {displayCount < wincentData.length && (
                <button className="load-more-btn" onClick={() => setDisplayCount(displayCount + 10)}>See More</button>
            )}
        </div>
    );
}

export default Wincent;
