import React from 'react';

function MarketUpdates({ marketPrices }) {
    const getClassForPercentage = (percentage) => {
        if (percentage < 0) {
            return "negative-change"; // Class for negative numbers
        } else if (percentage > 0) {
            return "positive-change"; // Class for positive numbers
        } else {
            return "";
        }
    };

    return (
        <div>
            <table>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Value (USD)</th>
                        <th>24H Change</th>
                        <th>24H Change (%)</th>
                        <th>Last Updated</th>
                    </tr>
                </thead>
                <tbody>
                    {marketPrices.map((item, index) => (
                        <tr key={index}>
                            <td>{item.name}</td>

                            <td>
                                {item.name === '10-year Treasuries'
                                    ? `${(item['value (usd)'] * 100).toFixed(2)}%`
                                    : item['value (usd)'] !== undefined && typeof item['value (usd)'] === 'number'
                                    ? item['value (usd)'].toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    })
                                    : 'N/A'}
                            </td>

                            <td>
                                {item.name !== '10-year Treasuries' && item['24HChange'] !== undefined
                                    ? typeof item['24HChange'] === 'number'
                                        ? item['24HChange'].toLocaleString(undefined, {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        })
                                        : 'N/A'
                                    : item.name === '10-year Treasuries' && item['24HChange'] !== undefined
                                    ? `${(item['24HChange'] * 100).toFixed(2)}%`
                                    : 'N/A'}
                            </td>

                            <td className={getClassForPercentage(item['24HChange (%)'])}>
                                {item['24HChange (%)'] !== undefined && typeof item['24HChange (%)'] === 'number'
                                    ? `${(item['24HChange (%)'] * 100).toFixed(2)}%`
                                    : 'N/A'}
                            </td>

                            <td>{item.lastUpdated || 'N/A'}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default MarketUpdates;
