import React, { useState, useEffect } from 'react';
import ClearpoolYields from './components/ClearpoolYields';
import DefiYield from './components/DefiYield';
import SpotFutures from './components/SpotFutures';
import MarketUpdates from './components/MarketUpdates';
import Wincent from './components/Wincent';
import NickelNew from './components/NickelNew';
import Kbit from './components/Kbit';
import PasswordProtection from './components/PasswordProtection';
import Deribit from './components/Deribit';
import './App.css';

const formatCurrency = (value) => {
  return `$${value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
};

const formatPercentage = (value) => {
  return `${(value * 100).toFixed(2)}%`;
};

function App() {
  const [activeTab, setActiveTab] = useState('Market Updates');
  const [passwordEntered, setPasswordEntered] = useState(false);
  const [marketPrices, setMarketPrices] = useState([]);
  const [spotFutures, setSpotFutures] = useState([]);
  const [clearpoolYields, setClearpoolYields] = useState([]);
  const [defiYield, setDefiYield] = useState([]);
  const [wincentData, setWincentData] = useState([]);
  const [nickelNewData, setNickelNewData] = useState([]);
  const [kbitData, setKbitData] = useState([]);
  const [marketPricesError, setMarketPricesError] = useState(null);
  const [spotFuturesError, setSpotFuturesError] = useState(null);
  const [clearpoolYieldsError, setClearpoolYieldsError] = useState(null);
  const [defiYieldError, setDefiYieldError] = useState(null);
  const [wincentDataError, setWincentDataError] = useState(null);
  const [nickelNewDataError, setNickelNewDataError] = useState(null);
  const [KbitDataError, setKbitDataError] = useState(null);
  const [displayCount, setDisplayCount] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [darkMode, setDarkMode] = useState(() => {
    return localStorage.getItem('darkMode') === 'true';
  });

  const oneHourInMilliseconds = 3600000;
  const initialLoadDelay = 0;

  // ✅ Fetch data periodically
  useEffect(() => {
    const fetchDataInterval = setInterval(() => {
      if (passwordEntered) {
        fetchData();
      }
    }, 30000);

    // Initial data load
    if (passwordEntered) {
      fetchData();
    }

    return () => clearInterval(fetchDataInterval);
  }, [passwordEntered]);

  // ✅ Check password expiration
  useEffect(() => {
    const lastPasswordEntryTimestamp = localStorage.getItem('passwordEntryTimestamp');
    const currentTime = new Date().getTime();

    if (lastPasswordEntryTimestamp && currentTime - lastPasswordEntryTimestamp < oneHourInMilliseconds) {
      setPasswordEntered(true);
    }
  }, []);

  // ✅ Apply dark mode
  useEffect(() => {
    if (darkMode) {
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
    }
    localStorage.setItem('darkMode', darkMode);
  }, [darkMode]);

  const handlePasswordEntry = () => {
    const currentTime = new Date().getTime();
    localStorage.setItem('passwordEntryTimestamp', currentTime);
    setPasswordEntered(true);
  };

  const fetchMarketUpdates = () => {
    const marketPricesUrl = 'https://api.sheety.co/cd8d5925e10bc14b1b986b20a83c50f0/marketrecap/marketPrices';
    return fetch(marketPricesUrl)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then(json => {
        setMarketPrices(json.marketPrices || []);
      })
      .catch(error => {
        console.error('Error fetching Market Prices: ', error);
        setMarketPricesError(error);
      });
  };

  const fetchSpotFutures = () => {
    const spotFuturesUrl = 'https://api.sheety.co/cd8d5925e10bc14b1b986b20a83c50f0/marketrecap/spotfutures';
    return fetch(spotFuturesUrl)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then(json => {
        setSpotFutures(json.spotfutures || []);
      })
      .catch(error => {
        console.error('Error fetching Spot Futures data: ', error);
        setSpotFuturesError(error);
      });
  };

  // Fetch Clearpool Yields data
  const fetchClearpoolYields = () => {
    const url = 'https://api.sheety.co/cd8d5925e10bc14b1b986b20a83c50f0/marketrecap/clearpoolYield';
    return fetch(url)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then(json => {
        const clearpoolYieldsData = json.clearpoolYield || [];
        const modifiedClearpoolYields = clearpoolYieldsData.map(item => ({
          borrower: item.borrower,
          poolSize: formatCurrency(item.poolSize),
          utilization: formatPercentage(item.utilization),
          yield: formatPercentage(item.yield),
          lastUpdated: item.lastUpdated, // Add the lastUpdated field
        }));
        setClearpoolYields(modifiedClearpoolYields);
      })
      .catch(error => {
        console.error('Error fetching Clearpool Yields data: ', error);
        setClearpoolYieldsError(error);
      });
  };

  // Fetch DeFi Yield data
  const fetchDefiYield = () => {
    const url = 'https://api.sheety.co/cd8d5925e10bc14b1b986b20a83c50f0/marketrecap/deFiYield';
    return fetch(url)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then(json => {
        setDefiYield(json.deFiYield || []);
      })
      .catch(error => {
        console.error('Error fetching DeFi Yield data: ', error);
        setDefiYieldError(error);
      });
  };

  // Fetch Wincent data
  const fetchWincentData = () => {
    const url = 'https://api.sheety.co/cd8d5925e10bc14b1b986b20a83c50f0/dashboard/wincent';
    const bearerToken = '49*Z6XA2fqAd9dIC*UPUaeJd';
    return fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${bearerToken}`,
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then(json => {
        setWincentData(json.wincent || []);
      })
      .catch(error => {
        console.error('Error fetching Wincent data: ', error);
        setWincentDataError(error);
      });
  };

  // Fetch Nickel (New) data
  const fetchNickelNewData = () => {
    const url = 'https://api.sheety.co/cd8d5925e10bc14b1b986b20a83c50f0/kbit/nickel';
    const bearerToken = 'iWiOu*vSSY$$PL1b';
  
    return fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${bearerToken}`,
      },
    })
      .then(response => response.json())
      .then(json => {
        console.log("Nickel (New) API Response:", json);
        setNickelNewData(json.nickel || []);
      })
      .catch(error => {
        console.error('Error fetching Nickel (New) data:', error);
        setNickelNewDataError(error);
      });
  };

  // Fetch Kbit data
  const fetchKbitData = () => {
    const url = 'https://api.sheety.co/cd8d5925e10bc14b1b986b20a83c50f0/kbit/kbit';
    const bearerToken = 'iWiOu*vSSY$$PL1b';
  
    return fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${bearerToken}`,
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then(json => {
        setKbitData(json.kbit || []);
      })
      .catch(error => {
        console.error('Error fetching Kbit data: ', error);
        setKbitDataError(error);
      });
  };
  

  // Fetch Loan Book data
//  const fetchLoanBook = () => {
//     const url = 'https://api.sheety.co/cd8d5925e10bc14b1b986b20a83c50f0/dashboard/loanBook';
//     const bearerToken = '49*Z6XA2fqAd9dIC*UPUaeJd';
//     return fetch(url, {
//       method: 'GET',
//       headers: {
//         'Authorization': `Bearer ${bearerToken}`,
//       },
//     })
//       .then(response => {
//         if (!response.ok) {
//           throw new Error(`HTTP error! Status: ${response.status}`);
//         }
//         return response.json();
//       })
//       .then(json => {
//         const loanBookData = json.loanBook || [];
//         const formattedLoanBook = loanBookData.map(item => ({
//           loanNo: item.loanNo,
//           loanAmount: formatCurrency(item.loanAmount),
//           currency: item.currency,
//           interestRate: formatPercentage(item['interestRate (%)']),
//           navDate: item.navDate,
//           interestDue: formatCurrency(item.interestDue),
//           id: item.id,
//         }));
//         setLoanBook(formattedLoanBook);
//       })
//       .catch(error => {
//         console.error('Error fetching Loan Book data: ', error);
//         setLoanBookError(error);
//       });
//   }; 

  
  // Fetch Nickel data
  // const fetchNickelData = () => {
  //   const url = 'https://api.sheety.co/cd8d5925e10bc14b1b986b20a83c50f0/dashboard/nickel';
  //   const bearerToken = '49*Z6XA2fqAd9dIC*UPUaeJd';
  //   return fetch(url, {
  //     method: 'GET',
  //     headers: {
  //       'Authorization': `Bearer ${bearerToken}`,
  //     },
  //   })
  //     .then(response => {
  //       if (!response.ok) {
  //         throw new Error(`HTTP error! Status: ${response.status}`);
  //       }
  //       return response.json();
  //     })
  //     .then(json => {
  //       setNickelData(json.nickel || []);
  //     })
  //     .catch(error => {
  //       console.error('Error fetching Nickel data: ', error);
  //       setNickelDataError(error);
  //     });
  // };

  // Fetch all data
  const fetchData = async () => {
    try {
      const responses = await Promise.all([
        fetchMarketUpdates(),
        fetchSpotFutures(),
        fetchClearpoolYields(),
        fetchDefiYield(),
        fetchNickelNewData(),
        fetchWincentData(),
        fetchKbitData(),
        // fetchLoanBook(),
        // fetchNickelData(),
      ]);

      const data = await Promise.all(responses.map((response) => response.json()));

      // Set data for each state
      setMarketPrices(data[0].marketPrices || []);
      setSpotFutures(data[1].spotfutures || []);
      setClearpoolYields(data[2].clearpoolYield || []);
      setDefiYield(data[3].deFiYield || []);
      setWincentData(data[4].wincent || []);
      setNickelNewData(data[5].nickel || []);
      setKbitData(data[6].kbit || []);
      // setLoanBook(data[7].loanBook || []);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data: ', error);
      setIsLoading(false);
    }
  };

  const renderActiveTab = () => {
    switch (activeTab) {
      case 'Market Updates':
        return <MarketUpdates marketPrices={marketPrices} />;
      case 'Spot Futures':
        return <SpotFutures spotFutures={spotFutures} />;
      case 'Clearpool Yields':
        return <ClearpoolYields clearpoolYields={clearpoolYields} />;
      case 'DeFi Yield':
        return <DefiYield defiYield={defiYield} />;
      case 'Wincent':
        return (
          <Wincent
            wincentData={wincentData}
            displayCount={displayCount}
            setDisplayCount={setDisplayCount}
          />
        );
      case 'Nickel (New)':
        return <NickelNew nickelNewData={nickelNewData} />;
      case 'Kbit':
        return <Kbit kbitData={kbitData} />;
      // case 'Loan Book':
        // return <LoanBook loanBook={loanBook} />;
      case 'Deribit':
        return <Deribit />;
      default:
        return null;
    }
  };

  return (
    <div className="Azure Tide">
      {!passwordEntered ? (
        <PasswordProtection onPasswordEntry={handlePasswordEntry} />
      ) : (
        <div>
          <header className="header">
            <h1>Azure Tide Dashboard</h1>
            {/* ✅ Dark Mode Toggle Button */}
            <button className="dark-mode-toggle" onClick={() => setDarkMode(!darkMode)}>
              {darkMode ? '☀️ Light Mode' : '🌙 Dark Mode'}
            </button>
          </header>

          <div className="sidebar">
            <ul>
              <li className="sidebar-button" onClick={() => setActiveTab('Market Updates')}>Market Updates</li>
              <li className="sidebar-button" onClick={() => setActiveTab('Spot Futures')}>Spot Futures</li>
              <li className="sidebar-button" onClick={() => setActiveTab('Clearpool Yields')}>Clearpool Yields</li>
              <li className="sidebar-button" onClick={() => setActiveTab('DeFi Yield')}>DeFi Yield</li>
              <li className="sidebar-button" onClick={() => setActiveTab('Wincent')}>Wincent</li>
              <li className="sidebar-button" onClick={() => setActiveTab('Nickel (New)')}>Nickel</li>
              <li className="sidebar-button" onClick={() => setActiveTab('Kbit')}>Kbit</li>
              <li className="sidebar-button" onClick={() => setActiveTab('Deribit')}>Deribit</li>
            </ul>
          </div>

          <div className="main-content">
            {renderActiveTab()}
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
