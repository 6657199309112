import React from 'react';

function NickelNew({ nickelNewData }) {
    const getClassForChange = (change) => {
        if (change < 0) {
            return "negative-change"; // Class for negative numbers
        } else if (change > 0) {
            return "positive-change"; // Class for positive numbers
        } else {
            return "";
        }
    };

    return (
        <div>
            <table>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>MTD Estimate (%)</th>
                        <th>YTD Estimate (%)</th>
                    </tr>
                </thead>
                <tbody>
                    {nickelNewData.map((item, index) => (
                        <tr key={index}>
                            <td>{item.date}</td>
                            <td className={getClassForChange(item["mtdEstimate (%)"])}>
                                {(item["mtdEstimate (%)"] * 100).toFixed(2)}%
                            </td>
                            <td className={getClassForChange(item["ytdEstimate (%)"])}>
                                {(item["ytdEstimate (%)"] * 100).toFixed(2)}%
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default NickelNew;
