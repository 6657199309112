import React, { useEffect, useState } from 'react';
import './Deribit.css';

function Deribit() {
  const [dVol, setDVol] = useState(null);
  const [delta, setDelta] = useState(null);
  const [positions, setPositions] = useState([]);
  const [originalPositions, setOriginalPositions] = useState([]); // Store default order
  const [error, setError] = useState(null);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState(null); // 'asc' | 'desc' | null (default)

  useEffect(() => {
    fetch('https://api.sheety.co/cd8d5925e10bc14b1b986b20a83c50f0/deribitDashboard/dVol', {
      headers: { Authorization: 'Bearer 9&^&kSR7uIeJqhq2' },
    })
      .then(res => res.json())
      .then(data => setDVol(data.dVol[data.dVol.length - 1]))
      .catch(setError);

    fetch('https://api.sheety.co/cd8d5925e10bc14b1b986b20a83c50f0/deribitDashboard/delta', {
      headers: { Authorization: 'Bearer 9&^&kSR7uIeJqhq2' },
    })
      .then(res => res.json())
      .then(data => setDelta(data.delta?.[data.delta.length - 1] || null))
      .catch(setError);

    fetch('https://api.sheety.co/cd8d5925e10bc14b1b986b20a83c50f0/deribitDashboard/positions', {
      headers: { Authorization: 'Bearer 9&^&kSR7uIeJqhq2' },
    })
      .then(res => res.json())
      .then(data => {
        setPositions(data.positions);
        setOriginalPositions(data.positions); // Save original order
      })
      .catch(setError);
  }, []);

  // 🟥🟨🟩 Color Coding for BTC & ETH dVol
  const getBTCColorClass = (value) => {
    if (value >= 72) return 'red-bg'; // ❌ Don't Buy
    if (value > 66 && value <= 70) return 'yellow-bg'; // ⚠️ Buy Less
    if (value >= 20 && value <= 64) return 'green-bg'; // ✅ Buy
    return 'white-bg';
  };

  const getETHColorClass = (value) => {
    if (value >= 76) return 'red-bg'; // ❌ Don't Buy
    if (value > 67 && value <= 76) return 'yellow-bg'; // ⚠️ Buy Less
    if (value >= 20 && value <= 66) return 'green-bg'; // ✅ Buy
    return 'white-bg';
  };

  // 🔄 Sorting Function with Reset to Default
  const sortPositions = (column) => {
    let newDirection = 'asc';
    if (sortColumn === column) {
      if (sortDirection === 'asc') {
        newDirection = 'desc';
      } else if (sortDirection === 'desc') {
        newDirection = null; // Reset to default
        setPositions(originalPositions); // Restore original order
        setSortColumn(null);
        setSortDirection(null);
        return;
      }
    }

    const sortedPositions = [...positions].sort((a, b) => {
      let valueA = parseFloat(a[column]);
      let valueB = parseFloat(b[column]);

      if (isNaN(valueA)) valueA = -Infinity;
      if (isNaN(valueB)) valueB = -Infinity;

      return newDirection === 'asc' ? valueA - valueB : valueB - valueA;
    });

    setSortColumn(column);
    setSortDirection(newDirection);
    setPositions(sortedPositions);
  };

  // Format Numbers
  const formatNumber = num => num.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });

  return (
    <div>
      <h1>Deribit Dashboard</h1>
      {error && <p>Error fetching data: {error.message}</p>}

      {/* 📊 Volatility Data */}
      {dVol && (
        <div>
          <h2>Volatility Data</h2>
          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>BTC dVol</th>
                <th>ETH dVol</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{dVol.date}</td>
                <td className={getBTCColorClass(dVol.btcDvol)}>{dVol.btcDvol}</td>
                <td className={getETHColorClass(dVol.ethDvol)}>{dVol.ethDvol}</td>
              </tr>
            </tbody>
          </table>

          {/* 🔹 Legend Section */}
          <div className="legend">
            <h3>Legend:</h3>
            <div><span className="green-bg legend-box"></span> Buy ✅</div>
            <div><span className="yellow-bg legend-box"></span> Buy Less ⚠️</div>
            <div><span className="red-bg legend-box"></span> Don't Buy ❌</div>
          </div>
        </div>
      )}

      {/* 📉 Delta Data */}
      {delta && (
        <div>
          <h2>Delta Data</h2>
          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>BTC Delta</th>
                <th>ETH Delta</th>
                <th>BTC Spot Price</th>
                <th>ETH Spot Price</th>
                <th>Impact for 10% rise in Bitcoin</th>
                <th>Impact for 10% rise in ETH</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{delta.date}</td>
                <td>{formatNumber(delta.btcDelta)}</td>
                <td>{formatNumber(delta.ethDelta)}</td>
                <td>{formatNumber(delta.btcspotprice)}</td>
                <td>{formatNumber(delta.ethspotprice)}</td>
                <td>{formatNumber(delta.btcEstimatedImpact)}</td>
                <td>{formatNumber(delta.ethEstimatedImpact)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}

      {/* 📌 Positions Table with Sorting */}
      {positions.length > 0 && (
        <div>
          <h2>Positions</h2>
          <table>
            <thead>
              <tr>
                <th>Instrument</th>
                <th onClick={() => sortPositions('size')} className="sortable">
                  Size {sortColumn === 'size' ? (sortDirection === 'asc' ? '⬆️' : sortDirection === 'desc' ? '⬇️' : '🔄') : ''}
                </th>
                <th>Direction</th>
                <th onClick={() => sortPositions('delta')} className="sortable">
                  Delta {sortColumn === 'delta' ? (sortDirection === 'asc' ? '⬆️' : sortDirection === 'desc' ? '⬇️' : '🔄') : ''}
                </th>
                <th onClick={() => sortPositions('deltaSizePercentage')} className="sortable">
                  Delta / Size {sortColumn === 'deltaSizePercentage' ? (sortDirection === 'asc' ? '⬆️' : sortDirection === 'desc' ? '⬇️' : '🔄') : ''}
                </th>
                <th>Last Updated</th>
              </tr>
            </thead>
            <tbody>
              {positions.map((pos, index) => (
                <tr key={index}>
                  <td>{pos.instrumentName}</td>
                  <td>{formatNumber(pos.size)}</td>
                  <td>{pos.direction}</td>
                  <td>{formatNumber(pos.delta)}</td>
                  <td>{formatNumber(pos.deltaSizePercentage)}%</td>
                  <td>{pos.lastUpdated}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default Deribit;
